import { FilterConfig, SelectType } from '@supy/components';

export interface PlaceOrderChannelItemsMappedFilters {
  readonly categories: string[];
}

export function getPlaceOrderChannelItemsFiltersConfig(): FilterConfig {
  return {
    search: {
      placeholder: $localize`:@@searchByItemPackagingName:Search by item packaging name`,
    },
    dropdowns: [
      {
        placeholder: $localize`:@@categories:Categories`,
        name: 'categories',
        width: '16rem',
        selectType: SelectType.DropdownTree,
        options: [],
        multiple: true,
        multipleSelectionStrategy: 'children',
      },
    ],
  };
}
