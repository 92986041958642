import { InventoryChannelItem, InventoryChannelItemApi } from '@supy/channel-items';

export function getFillToParBaseQuantity({
  quantityLevels,
  packaging,
}: InventoryChannelItemApi | InventoryChannelItem): number | undefined {
  const par = quantityLevels?.par ?? 0;
  const stock = quantityLevels?.stock ?? 0;

  if (par === 0 || stock >= par) {
    return undefined;
  }

  return packaging?.quantity?.baseUnit ? Math.floor((par - stock) / packaging.quantity.baseUnit) : 0;
}
