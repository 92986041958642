import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IgxGridModule } from '@infragistics/igniteui-angular';
import { NgxsModule } from '@ngxs/store';

import { ChannelItemsModule } from '@supy/channel-items';
import { ChannelResolver, ChannelsModule } from '@supy/channels';
import { SupyCommonModule, UnleashModule } from '@supy/common';
import {
  AvatarModule,
  ButtonModule,
  CounterInputModule,
  DateInputModule,
  DialogModule,
  DropdownTreeModule,
  FileUploadModule,
  FiltersModule,
  GridModule,
  IconModule,
  InputModule,
  LoadingOverlayModule,
  SearchInputModule,
  SelectModule,
  TextareaModule,
  TooltipModule,
} from '@supy/components';
import { OrdersModule } from '@supy/orders';
import { RetailerItemCategoriesModule } from '@supy/retailer-item-categories';
import { RetailerItemsModule, RetailerItemsState } from '@supy/retailer-items';
import { SettingsState } from '@supy/settings';

import { OrderingPlaceholderComponent, PlaceOrderComponent, ReviewOrderComponent } from './components';
import { PlaceOrderRoutingModule } from './place-order-routing.module';
import { SupplierChannelsResolver, SupplierResolver } from './resolvers';

@NgModule({
  declarations: [PlaceOrderComponent, ReviewOrderComponent, OrderingPlaceholderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PlaceOrderRoutingModule,
    DialogModule,
    ButtonModule,
    IconModule,
    SearchInputModule,
    LoadingOverlayModule,
    IgxGridModule,
    CounterInputModule,
    InputModule,
    ReactiveFormsModule,
    DateInputModule,
    AvatarModule,
    OrdersModule,
    ChannelsModule,
    TextareaModule,
    ChannelItemsModule,
    GridModule,
    SelectModule,
    RetailerItemsModule,
    FileUploadModule,
    DropdownTreeModule,
    SupyCommonModule,
    UnleashModule,
    RetailerItemCategoriesModule,
    NgxsModule.forFeature([RetailerItemsState, SettingsState]),
    FiltersModule,
    TooltipModule,
  ],
  providers: [ChannelResolver, SupplierChannelsResolver, SupplierResolver],
})
export class PlaceOrderModule {}
