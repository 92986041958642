import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Supplier } from '@supy/common';
import { SuppliersService } from '@supy/suppliers';

@Injectable()
export class SupplierResolver {
  constructor(private readonly suppliersService: SuppliersService) {}
  resolve(route: ActivatedRouteSnapshot): Supplier | Observable<Supplier> | Promise<Supplier> {
    return this.suppliersService.getSupplier(route.queryParamMap.get('supplierId'));
  }
}
