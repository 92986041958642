import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'supy-other-comments',
  templateUrl: './other-comments.component.html',
  styleUrls: ['./other-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherCommentsComponent {
  @Input() disabled: boolean;
  @Input() value: string;
  @Input() title = $localize`:@@common.otherDetails:Other Details`;
  @Input() showLabel: boolean;

  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly valueSubmit = new EventEmitter<string>();

  onChange: (value: string) => void;

  onTouched: () => void;

  get formControl(): FormControl<string> {
    return (this.control?.control as FormControl<string>) ?? this.#simpleControl;
  }

  readonly #simpleControl = new FormControl<string>('');

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onValueChange(value: string): void {
    this.writeValue(value);

    this.onChange?.(value);

    this.valueChange.emit(value);
  }

  onFocusOut() {
    this.valueSubmit.emit(this.value);
  }

  onSubmit(value: string) {
    this.valueSubmit.emit(value);
  }
}
