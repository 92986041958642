import { computed, Injectable, signal } from '@angular/core';

import { InventoryChannelItem } from '@supy/channel-items';

import { getFillToParBaseQuantity } from '../helpers';

@Injectable({ providedIn: 'root' })
export class PlaceOrderService {
  readonly basket = signal<InventoryChannelItem[]>([]);
  readonly basketMap = computed(() => new Map(this.basket().map(item => [item.id, item])));
  readonly navigatedToReview = signal<boolean>(false);

  setBasketItems(items: InventoryChannelItem[]): void {
    this.basket.set(items);
  }

  updateBasketItem(item: InventoryChannelItem): void {
    if (this.basket().length > 0) {
      if (!this.basketMap().has(item.id)) {
        item.quantity > 0 && this.basket.update(items => items.concat(item));
      } else {
        if (item.quantity > 0) {
          this.basket.update(items => items.map(orderItem => (orderItem.id === item.id ? item : orderItem)));
        } else {
          this.basket.update(items => {
            const itemIndex = items.findIndex(({ id }) => item.id === id);

            items.splice(itemIndex, 1);

            return items;
          });
        }
      }
    } else {
      this.basket.set([item]);
    }
  }

  appendBasketItems(fillableChannelItems: InventoryChannelItem[]): void {
    const channelItemsMap = new Map(fillableChannelItems.map(channelItem => [channelItem.id, channelItem]));

    const newlyAddedChannelItems = fillableChannelItems
      .filter(channelItem => !this.basketMap().has(channelItem.id) && getFillToParBaseQuantity(channelItem))
      .map(channelItem => ({
        ...channelItem,
        quantity: getFillToParBaseQuantity(channelItem),
      }));

    this.basket.update(items =>
      items
        .map(item => {
          const fillToParQuantity = channelItemsMap.has(item.id)
            ? getFillToParBaseQuantity(channelItemsMap.get(item.id))
            : item.quantity;

          return fillToParQuantity > 0 ? { ...item, quantity: fillToParQuantity } : item;
        })
        .concat(newlyAddedChannelItems),
    );
  }

  clearBasket(): void {
    this.basket.set([]);
  }
}
