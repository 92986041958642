import { finalize, first } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { FormComponent, Query, Supplier, SupplierType } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { BranchesService, CurrentRetailerState } from '@supy/retailers';

export interface FormValue {
  readonly branchIds: string[] | null;
  readonly supplierIds: string[] | null;
}

@Component({
  selector: 'supy-select-branch-and-supplier-dialog',
  templateUrl: './select-branch-and-supplier-dialog.component.html',
  styleUrls: ['./select-branch-and-supplier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBranchAndSupplierDialogComponent
  extends FormComponent<FormValue>
  implements IDialogComponent, OnInit
{
  readonly #branchesService = inject(BranchesService);
  readonly #store = inject(Store);
  readonly #injector = inject(Injector);
  readonly #branchesValueChanges = toSignal(this.getValueChanges('branchIds'));

  @Input() readonly title: string;
  @Input() readonly cancelText?: string = $localize`:@@cancel:Cancel`;
  @Input() readonly confirmText?: string = $localize`:@@common.actions.submit:Submit`;
  @Input() readonly navigateOnConfirm?: boolean;
  @Input() readonly redirectUrl?: string[];
  @Input() readonly includeCentralKitchens?: boolean;
  @Input() readonly overlaySettings?: OverlaySettings;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<FormValue>();
  @Output() readonly cancel = new EventEmitter<void>();

  protected readonly branches = toSignal(this.#store.select(CurrentRetailerState.branches));
  protected readonly suppliers = signal<Supplier[]>([]);
  protected readonly areSuppliersLoading = signal<boolean>(false);

  constructor() {
    super({
      branchIds: [null, Validators.required],
      supplierIds: [null, Validators.required],
    });
  }

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  ngOnInit(): void {
    effect(
      () => {
        const branchIds = this.#branchesValueChanges() ?? [];

        if (branchIds.length > 0) {
          this.areSuppliersLoading.set(true);
          this.#branchesService
            .getBranchesSuppliers(
              branchIds,
              new Query<Supplier>({
                filtering: this.includeCentralKitchens
                  ? [{ by: 'state', op: 'eq', match: 'active' }]
                  : [
                      { by: 'state', op: 'eq', match: 'active' },
                      { by: 'type', op: 'neq', match: SupplierType.centralKitchen },
                    ],
              }),
            )
            .pipe(
              first(),
              finalize(() => this.areSuppliersLoading.set(false)),
            )
            .subscribe(({ data }) => {
              this.suppliers.set(data);
              this.reconfigure('supplierIds', {
                value: null,
                isDisabled: false,
              });
            });
        } else {
          this.reconfigure('supplierIds', {
            value: null,
            isDisabled: true,
          });
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  protected onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  protected onConfirmClick(): void {
    this.closeDialog();
    this.confirm.emit(this.getValue());

    if (this.navigateOnConfirm) {
      this.#store.dispatch(
        new Navigate(this.redirectUrl, {
          branchId: this.getValue('branchIds').at(0),
          supplierId: this.getValue('supplierIds').at(0),
        }),
      );
    }
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
