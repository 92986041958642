import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, Router } from '@angular/router';

import { PlaceOrderService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ReviewOrderGuard {
  constructor(
    private readonly router: Router,
    private readonly placeOrderService: PlaceOrderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const basket = this.placeOrderService.basket();

    if (basket.length > 0) {
      return true;
    } else {
      const urlTree = createUrlTreeFromSnapshot(route, ['../']);

      return this.router.navigateByUrl(urlTree);
    }
  }
}
