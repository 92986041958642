import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChannelResolver } from '@supy/channels';

import { PlaceOrderComponent, ReviewOrderComponent } from './components';
import { ReviewOrderGuard } from './guards/review-order.guard';

const routes: Routes = [
  {
    path: '',
    resolve: {
      channel: ChannelResolver,
    },
    children: [
      { path: '', redirectTo: 'place', pathMatch: 'full' },
      {
        path: 'place',
        component: PlaceOrderComponent,
        data: {
          pageName: 'Place Order',
        },
      },
      {
        path: 'review',
        component: ReviewOrderComponent,
        canActivate: [ReviewOrderGuard],
        data: {
          pageName: 'Review Order',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlaceOrderRoutingModule {}
