import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';

import { PlaceOrderService } from '../../services';

@Component({
  selector: 'supy-ordering-placeholder',
  templateUrl: './ordering-placeholder.component.html',
  styleUrls: ['./ordering-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderingPlaceholderComponent implements OnDestroy {
  readonly #placeOrderService = inject(PlaceOrderService);

  ngOnDestroy(): void {
    this.#placeOrderService.clearBasket();
  }
}
