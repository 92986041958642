import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, ComboboxModule, DialogModule } from '@supy/components';

import { SelectBranchAndSupplierDialogComponent } from './components';

const COMPONENTS = [SelectBranchAndSupplierDialogComponent];

@NgModule({
  declarations: [...COMPONENTS, SelectBranchAndSupplierDialogComponent],
  imports: [CommonModule, DialogModule, ComboboxModule, ButtonModule, ReactiveFormsModule],
  exports: [...COMPONENTS],
})
export class CommonComponentsModule {}
