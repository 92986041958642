import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SupyCommonModule } from '@supy/common';
import {
  AvatarModule,
  ButtonModule,
  ComboboxModule,
  DialogModule,
  SelectModule,
  StatusBadgeModule,
  TextareaModule,
} from '@supy/components';

import { OtherCommentsComponent } from './components';

const COMPONENTS = [OtherCommentsComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AvatarModule,
    TextareaModule,
    ButtonModule,
    DialogModule,
    ComboboxModule,
    StatusBadgeModule,
    SelectModule,
    SupyCommonModule,
  ],
  exports: [...COMPONENTS],
})
export class OrderReceivingModule {}
