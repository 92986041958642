<div class="supy-other-comments">
  <h6 *ngIf="title" class="supy-other-comments__title">{{ title }}</h6>

  <form class="supy-other-comments__body">
    <div class="supy-other-comments__input">
      <p *ngIf="showLabel" class="supy-other-comments__label">
        <span i18n="@@common.remarksCommentsNotes">Remarks/Comments/Notes</span>
      </p>
      <supy-textarea
        [formControl]="formControl"
        [value]="value"
        (valueChange)="onValueChange($event)"
        (focusOut)="onFocusOut()"
        (submitValue)="onSubmit($event)"
        i18n-placeholder="@@common.otherComments"
        placeholder="Enter Comment"
        [rows]="4"
        [disabled]="disabled"
      ></supy-textarea>
    </div>
  </form>
</div>
